import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import Table from 'app/components/Table';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import ModelDistributionListChart from './ModelDistributionListChart';
import HoldingsTableRow from './TableRow';

import { PdfViewerContext } from 'app/context';
import customLogo from 'assets/customModelLogo.png';
import { tw } from 'utils/tailwindSetup';
import {
  checkForNoModels,
  isModelTypeCustom,
  isParamContainsSage,
} from 'utils/helpers';
import { getMinWidthForHoldings } from './utils';
import { HEADER_ITEMS } from './constants';
import { PIE_CHART_COLORS_FOR_ALLOCATIONS } from 'utils/constants/pieChartColor';
import { HoldingsProps } from './types';

function Holdings({
  headerTitle = 'Recommended Portfolio - Holdings',
  recommendationData,
  blendedSubModels = [],
  modelLogoUrl = '',
  subModelsLogo = [],
}: HoldingsProps) {
  const rowsPerPage = 12;
  const rowsPerPageForSubModels = 10;
  const isSageDocument = isParamContainsSage();
  const { advisorDetails } = useContext(PdfViewerContext);

  const activeOptimizer = { isGOEOptimizer: false };
  const isBlendedModelsPresent =
    Array.isArray(blendedSubModels) && blendedSubModels?.length > 0;

  const modelName = useMemo(() => {
    return checkForNoModels(recommendationData?.baseModel)?.name ?? '';
  }, [recommendationData?.baseModel]);

  const isCustomModel = useMemo(() => {
    return !!isModelTypeCustom(recommendationData?.baseModel);
  }, [recommendationData?.baseModel]);

  const holdingsPages = useMemo(() => {
    const rowLength =
      (recommendationData?.holdings && recommendationData?.holdings?.length) ??
      0;
    const noofPages = Math.ceil(rowLength / rowsPerPage);
    return noofPages;
  }, [recommendationData?.holdings]);

  const subModelPages = useMemo(() => {
    const rowLength = (subModelsLogo && subModelsLogo?.length) ?? 0;
    const noofPages = Math.ceil(rowLength / 10);
    return noofPages;
  }, [subModelsLogo]);

  return (
    <>
      {isBlendedModelsPresent ? (
        Array.from(Array(subModelPages).keys()).map((_, index) => (
          <ProposalPage headerTitle={headerTitle}>
            <Text
              style={tw(
                'font-inter text-default font-medium text-base leading-[1.188rem] mb-2 min-h-5',
              )}
            >
              Summary
            </Text>
            <Text style={tw('font-inter text-neutral600 text-xs leading-4')}>
              Your recommended portfolio is a blended model composed of the
              following models:
            </Text>
            <ModelDistributionListChart
              modelName={modelName}
              data={
                blendedSubModels?.slice(
                  rowsPerPageForSubModels * index,
                  rowsPerPageForSubModels * (index + 1),
                ) ?? []
              }
              chartColors={PIE_CHART_COLORS_FOR_ALLOCATIONS}
              firmLogo={advisorDetails?.firmLogo ?? ''}
              subModelsLogo={
                subModelsLogo?.slice(
                  rowsPerPageForSubModels * index,
                  rowsPerPageForSubModels * (index + 1),
                ) ?? []
              }
            />
            <PageBottomContainer />
          </ProposalPage>
        ))
      ) : (
        <></>
      )}
      {Array.isArray(recommendationData?.holdings) &&
      recommendationData?.holdings.length > 0 ? (
        <>
          {Array.from(Array(holdingsPages).keys()).map((_, index) => (
            <ProposalPage headerTitle={headerTitle}>
              {modelName && (
                <View
                  fixed
                  style={tw(
                    'w-full flex flex-row justify-center items-center mb-4',
                  )}
                >
                  <View
                    style={tw(
                      'flex flex-row items-center border border-neutral200 rounded bg-white',
                    )}
                  >
                    <View
                      style={tw(
                        'w-1/2 flex flex-row justify-center items-center h-[3.75rem]',
                      )}
                    >
                      {!isSageDocument &&
                        (isCustomModel ? (
                          <Image
                            src={advisorDetails?.firmLogo || customLogo}
                            style={tw('h-[3.125rem] w-full object-contain')}
                          />
                        ) : (
                          <>
                            {modelLogoUrl && (
                              <Image
                                style={tw(
                                  `max-h-[${modelLogoUrl ? '3.125rem' : '3.75rem'}] h-full w-[4.8125rem] object-contain`,
                                )}
                                source={modelLogoUrl}
                              />
                            )}
                          </>
                        ))}
                    </View>
                    <View
                      style={tw(
                        'flex flex-row items-center w-1/2 mx-0 my-auto bg-neutral100 rounded-r rounded-tl-none h-[3.75rem]',
                      )}
                    >
                      <Text
                        style={tw(
                          'text-neutral600 font-medium text-xs p-2 m-3 w-full',
                        )}
                      >
                        {checkForNoModels(recommendationData?.baseModel)
                          ?.name ?? ''}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <Table
                tableHeaders={HEADER_ITEMS}
                tableData={
                  recommendationData?.holdings?.slice(
                    rowsPerPage * index,
                    rowsPerPage * (index + 1),
                  ) ?? []
                }
                getMinWidth={getMinWidthForHoldings}
                CustomTableRow={HoldingsTableRow}
                showTotal={index === holdingsPages - 1}
                totalFields={{
                  types: ['WEIGHT', 'PRICE'],
                  values: ['weight', 'value'],
                }}
                dataForCalculatingTotal={recommendationData?.holdings ?? []}
                breakPage={false}
              />
              <PageBottomContainer>
                <Disclosure
                  text={[
                    `The Recommended Portfolio is selected by your Financial Professional. Risk Scores shown are ${isSageDocument ? `Sage’s` : `TIFIN Wealth’s`}  measure for how risky a particular holding is, with 1 being low-risk and 99 being high-risk.${
                      activeOptimizer?.isGOEOptimizer
                        ? `The ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} Risk Score is not linked to Franklin Templeton's Goals Optimization Engine.`
                        : ''
                    }`,
                    `Neither Asset Allocation nor Diversification guarantee a profit or protect against a loss in a declining market. They are methods used to help manage investment risk. Investing in international securities involves special additional risks. These risks include, but are not limited to, currency risk, political risk, and risk associated with varying accounting standards. Investing in emerging markets may accentuate these risks. Sector strategies subject the investor to increased industry-specific risks.`,
                    `Please consider the investment objectives, risks, charges, and expenses carefully before investing. The prospectus, which contains this and other information about the mutual funds and ETFs in the recommended portfolio, can be obtained from your financial professional. Be sure to read the prospectus carefully before deciding whether to invest.`,
                  ]}
                />
              </PageBottomContainer>
            </ProposalPage>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Holdings;
