import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';

import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { TableOfContentsProps } from './types';

function TableOfContent({
  pageList,
  headerTitle = 'Table of Contents',
}: TableOfContentsProps) {
  const isSageDocument = isParamContainsSage();
  return (
    <ProposalPage headerTitle={headerTitle}>
      {Array.isArray(pageList) &&
        pageList.length > 0 &&
        pageList.map((el, index) => {
          return (
            <View key={index} break={index !== 0 && index % 5 === 0}>
              {el && el?.elements.length > 0 && (
                <View style={tw('mb-[0.9375rem]')}>
                  <Text
                    style={tw(
                      'font-inter text-default font-semibold text-[0.9375rem] mb-2 pl-10',
                    )}
                    fixed
                  >
                    {el?.title}
                  </Text>
                  <View style={tw('pl-16')}>
                    {el?.elements.map((item, index) => (
                      <>
                        {item?.title && (
                          <Text
                            style={tw('font-inter text-default text-sm mb-2')}
                          >
                            {index + 1}. {item?.title}
                          </Text>
                        )}
                      </>
                    ))}
                  </View>
                </View>
              )}
            </View>
          );
        })}
      <PageBottomContainer>
        <Disclosure
          children={
            <>
              <View style={tw('mb-2')}>
                <Text style={tw('font-bold')}>
                  IMPORTANT: The projections or other information generated
                  regarding the likelihood of gains or losses are hypothetical
                  in nature, do not reflect actual investment results, and are
                  not guarantees of future results.
                </Text>
              </View>

              <View>
                <Text>
                  The information, data, analysis, and opinions contained in
                  this report include the confidential and proprietary
                  information of {isSageDocument ? 'SAGE' : 'TIFIN Wealth'}; may
                  include or be derived from account information provided by you
                  or your financial representative which cannot be verified by{' '}
                  {isSageDocument ? 'SAGE' : 'TIFIN Wealth'}. This information
                  may not be copied or redistributed; does not constitute
                  investment advice and is provided solely for informational
                  purposes only. This material does not constitute an offer to
                  buy or sell a security. Product suitability must be
                  independently determined for each individual investor. Except
                  as otherwise provided by law,{' '}
                  {isSageDocument ? 'SAGE' : 'TIFIN Wealth'} shall not be
                  responsible for any trading decisions, damages or other losses
                  resulting from the use of this information, data, analysis or
                  opinion. This report is supplemental sales literature. If
                  applicable, this report should be preceded or accompanied by a
                  prospectus, or equivalent, and disclosure statement.
                </Text>
              </View>
            </>
          }
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default TableOfContent;
