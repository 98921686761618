import React, { useContext } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import ProposalPage from 'app/components/ProposalPage';
import RiskScoreCircle from 'app/components/RiskScoreCircle';
import PieGraph from 'app/components/PieGraph';
import RiskBand from 'app/components/RiskBand';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';
import AssetAllocationLegends from 'app/components/AssetAllocationLegend';
import Table from 'app/components/Table';
import HoldingsTableRow from './HoldingsTableRow';

import { PdfViewerContext } from 'app/context';
import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { deriveRiskBandText, getMinWidthForHoldings } from './utils';
import { pieChartColor } from 'utils/constants/pieChartColor';
import { HEADER_ITEMS } from './constants';
import { RecommendationSnapshotProps } from './types';

const baseSubHeaderStyles = tw('text-[0.625rem] text-neutral500 mb-1');

function RecommendationSnapshot({
  recommendationData,
  headerTitle = 'Snapshot',
  modelLogoUrl = '',
  riskScore,
}: RecommendationSnapshotProps) {
  const isSageDocument = isParamContainsSage();
  const { advisorDetails } = useContext(PdfViewerContext);
  const assetAllocation = recommendationData.assetAllocation ?? [];
  const hasRiskBand = recommendationData.riskBand;

  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('flex flex-col gap-5')}>
        <Text style={tw('mb-2 text-sm font-medium')}>
          What Portfolio is Recommended?
        </Text>
        <View style={tw('flex flex-row items-start gap-[1.125rem]')}>
          {/* risk score and recommended model info */}
          <View style={tw('flex flex-col gap-3 basis-0 grow-[1.4]')}>
            <View>
              <Text style={baseSubHeaderStyles}>Selected Portfolio</Text>
              <View
                style={tw(
                  'p-5 rounded-lg border border-neutral200 flex flex-col gap-3',
                )}
              >
                <View style={tw('flex flex-col justify-between gap-2')}>
                  {!isSageDocument &&
                    advisorDetails?.firmLogo &&
                    modelLogoUrl && (
                      <Image
                        style={tw('h-[1.5625rem] w-full object-contain')}
                        source={modelLogoUrl}
                      />
                    )}
                  <Text style={tw('text-xs font-medium text-neutral600')}>
                    {recommendationData?.baseModel?.modelName ?? ''}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={baseSubHeaderStyles}>Portfolio Risk Score</Text>
              <View
                style={tw(
                  'p-5 rounded-lg border border-neutral200 flex flex-row items-center justify-center',
                )}
              >
                <RiskScoreCircle
                  score={riskScore ?? 0}
                  height={72}
                  width={72}
                  textSize={20}
                />
              </View>
            </View>
          </View>
          {/* asset allocation chart */}
          <View style={tw('flex flex-col grow-[2] justify-start basis-0')}>
            <Text style={baseSubHeaderStyles}>Asset Allocation</Text>
            <View style={tw('p-5 rounded-lg border border-neutral200')}>
              <View style={tw('flex flex-row items-center gap-8')}>
                <View style={tw('flex flex-row justify-center')}>
                  <PieGraph
                    labelSize={12}
                    data={assetAllocation ?? []}
                    height={150}
                    width={150}
                    innerRadius={48}
                    outerRadius={60}
                    label="Asset Allocation"
                    customColorWithLabels={pieChartColor}
                    allocationType="assetAllocation"
                  />
                </View>
                <View style={tw('h-full w-32')}>
                  <AssetAllocationLegends
                    allocationType="assetAllocation"
                    data={recommendationData}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        {hasRiskBand ? (
          <View>
            <Text style={tw('mb-2 text-sm font-medium')}>
              What is my Risk Band?
            </Text>
            <View
              style={tw('bg-[#ECF2FC] border border-[#B6CFF3] rounded p-2.5')}
            >
              <Text
                style={tw('font-inter text-default text-[0.625rem] leading-7')}
              >
                {deriveRiskBandText(
                  recommendationData?.riskBand,
                  riskScore ?? 0,
                ) ?? ''}
              </Text>
            </View>
            <View
              style={tw(
                'border border-neutral200 rounded w-full my-4 px-10 h-[14.375rem]',
              )}
            >
              <View style={tw('w-full m-auto')}>
                <RiskBand
                  riskData={recommendationData?.riskBand}
                  riskScores={[
                    {
                      value: riskScore ? +riskScore : 0,
                    },
                  ]}
                />
              </View>
            </View>
          </View>
        ) : (
          <View style={tw('mt-6')}>
            <Text style={tw('mb-2 text-sm font-medium')}>Top 10 Holdings</Text>
            <Table
              tableHeaders={HEADER_ITEMS}
              tableData={recommendationData?.holdings ?? []}
              getMinWidth={getMinWidthForHoldings}
              CustomTableRow={HoldingsTableRow}
              totalFields={{
                types: ['WEIGHT', 'PRICE'],
                values: ['weight', 'price'],
              }}
            />
          </View>
        )}
      </View>
      <PageBottomContainer>
        <Disclosure
          text={[
            `${isSageDocument ? `Sage’s` : `TIFIN  Wealth’s`} comprehensive approach looks at multiple risk factors to create your personalized risk band. We score both your Risk Preference (the risk you are willing to take) and Risk Capacity (your ability to take risk based on the facts of your financial life). While these scores can range from 1-99, we see most user scores fall within the Conservative to Growth range of 6-55. Ideally, your investment strategy (Portfolio Risk Score) would fall between your Risk Capacity and Risk Preference scores. This represents a hypothetical target that you would seek to keep your investments within. There is no guarantee that any investments would perform within this target.`,
            `The information, data, analysis, and opinions contained in this report include the confidential and proprietary information of ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} ; may include or be derived from account information provided by you or your financial representative which cannot be verified by ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} . This information may not be copied or redistributed; does not constitute investment advice and is provided solely for informational purposes only. This material does not constitute an offer to buy or sell a security. Product suitability must be independently determined for each individual investor. Except as otherwise provided by law, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} shall not be responsible for any trading decisions, damages or other losses resulting from the use of this information, data, analysis or opinion. This report is supplemental sales literature. If applicable, this report should be preceded or accompanied by a prospectus, or equivalent, and disclosure statement.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default RecommendationSnapshot;
