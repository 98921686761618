import React, { useMemo } from 'react';
import ProposalPage from 'app/components/ProposalPage';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';
import StatisticsInfo from './StatisticsInfo';
import { RiskStatisticsProps } from './types';

function RiskStatistics({
  data,
  headerTitle = 'Risk Statistics',
  isTemplateCurrentPortfolio = false,
  benchmarkName = '',
  historicalReturnsDateRange,
}: RiskStatisticsProps) {
  const period = useMemo(() => {
    if (data?.difference && !isNaN(data?.difference)) {
      /* Note: Difference is in months */
      switch (true) {
        case data?.difference >= 12 && data?.difference < 60:
          return 1;
        case data?.difference >= 60 && data?.difference < 120:
          return 5;
        case data?.difference >= 120:
          return 10;
        default:
          return 0;
      }
    }
    return 10;
  }, [data?.difference]);

  const volatilityAndDrawdown = useMemo(() => {
    const DEFAULT_VALUE = [
      {
        currentPortfolio: 0,
        recommendedPortfolio: 0,
        benchmark: 0,
        value: 150,
      },
      {
        currentPortfolio: 0,
        recommendedPortfolio: 0,
        benchmark: 0,
        value: -100,
      },
    ];
    // TODO: will integrate for wealth also benchmark data will be added for wealth later on
    if (period === 0) return DEFAULT_VALUE;
    return [
      {
        currentPortfolio: +(
          data?.currentPortfolio?.volatility?.toFixed(2) ?? 0
        ),
        recommendedPortfolio: +(
          data?.recommendedPortfolio?.volatility?.toFixed(2) ?? 0
        ),
        benchmark: +(data?.benchmark?.volatility?.toFixed(2) ?? 0),
        value: 150,
      },
      {
        currentPortfolio: +(data?.currentPortfolio?.drawdown?.toFixed(2) ?? 0),
        recommendedPortfolio: +(
          data?.recommendedPortfolio?.drawdown?.toFixed(2) ?? 0
        ),
        benchmark: +(data?.benchmark?.drawdown?.toFixed(2) ?? 0),
        value: -100,
      },
    ];
  }, [data, period]);

  const upAndDownCapture = useMemo(() => {
    const DEFAULT_VALUE = [
      {
        currentPortfolio: 0,
        recommendedPortfolio: 0,
        benchmark: 0,
        value: 150,
      },
      {
        currentPortfolio: 0,
        recommendedPortfolio: 0,
        benchmark: 0,
        value: -100,
      },
    ];
    if (period === 0) return DEFAULT_VALUE;
    return [
      {
        currentPortfolio: +(
          data?.currentPortfolio?.upsideDownCapture?.upsideCapture?.toFixed(
            2,
          ) ?? 0
        ),
        recommendedPortfolio: +(
          data?.recommendedPortfolio?.upsideDownCapture?.upsideCapture?.toFixed(
            2,
          ) ?? 0
        ),
        benchmark:
          data?.benchmark?.upsideDownCapture?.upsideCapture?.toFixed(2) ?? 0,
        value: 150,
      },
      {
        currentPortfolio: +(
          data?.currentPortfolio?.upsideDownCapture?.downsideCapture?.toFixed(
            2,
          ) ?? 0
        ),
        recommendedPortfolio: +(
          data?.recommendedPortfolio?.upsideDownCapture?.downsideCapture?.toFixed(
            2,
          ) ?? 0
        ),
        benchmark:
          data?.benchmark?.upsideDownCapture?.downsideCapture?.toFixed(2) ?? 0,
        value: -100,
      },
    ];
  }, [data, period]);

  return (
    <ProposalPage headerTitle={headerTitle}>
      <StatisticsInfo
        data={volatilityAndDrawdown}
        statisticType="VOLATILITY_DRAWDOWN"
        period={period}
        isTemplateCurrentPortfolio={isTemplateCurrentPortfolio}
        benchmarkName={benchmarkName}
        dateRange={historicalReturnsDateRange}
      />
      <StatisticsInfo
        data={upAndDownCapture}
        statisticType="UP_DOWN_CAPTURE"
        period={period}
        isTemplateCurrentPortfolio={isTemplateCurrentPortfolio}
        benchmarkName={benchmarkName}
        dateRange={historicalReturnsDateRange}
      />
      <PageBottomContainer>
        <Disclosure
          minHeight={false}
          text={[
            `Important: Advisor Selected relative securities are used in this analysis and are not official benchmark index returns provided by the index creator. All indices are unmanaged, and investors cannot actually invest directly into an index. Unlike investments, indices do not incur management fees, charges, or expenses. Advisors can select the security to conduct this relative performance, in doing so, can introduce some degree of tracking error against the actual index. `,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default RiskStatistics;
