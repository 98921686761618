import React, { useContext } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import ProposalPage from 'app/components/ProposalPage';
import { PdfViewerContext } from 'app/context';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { IPSPageProps } from './types';
import { keyFactors, delibration } from './utils';

const baseTextStyle = tw('text-[0.625rem] text-default leading-6 font-inter');
const secondaryTextStyleForListHeader = tw(
  'font-inter mt-1 mb-2 text-default text-[0.625rem] leading-4',
);
const secondaryTextStyleForList = tw(
  'font-inter text-[0.625rem] pl-2.5 mt-0 text-default leading-6',
);

function IPS({
  showDisclosure = true,
  headerTitle = 'IPS Introduction',
}: IPSPageProps) {
  const { advisorDetails: advisorInfo, clientDetails } =
    useContext(PdfViewerContext);
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('mb-8')}>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            This Investment Policy Statement seeks to establish a clear
            understanding between {''}
            {clientDetails.name}, (the Client) and {advisorInfo.name} of{' '}
            {advisorInfo.firmName} (collectively, the Advisor) with regards to
            the investment goals, risk profile, and policies applicable to the
            Client's investment portfolio.
          </Text>
        </View>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            This report provides a general overview of some aspects of your
            personal financial situation. It is intended to review your current
            situation and suggest potential planning ideas and concepts that may
            improve your current overall situation through the use of various
            financial and estate planning principles. All assumptions are based
            on information that you have provided. You are solely responsible
            for the accuracy or completeness of the information you have
            provided and how that information may affect the results and any
            recommendations contained in the report.
          </Text>
        </View>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            It provides a detailed look at the recommended asset allocations and
            money managers selected to assist you with meeting financial goals.
            Allocation percentages are subject to change without notice based on
            an attempt to keep the allocation in line with the investment
            objective.
          </Text>
        </View>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            THIS REPORT IS FOR ILLUSTRATION PURPOSES ONLY, IN ONE-ON-ONE
            PRESENTATIONS WITH YOUR FINANCIAL ADVISOR.
          </Text>
        </View>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            This report has been developed from an evaluation of many key
            factors which impact the investor’s specific situation, risk
            tolerance and investment objectives. This is not a contract but
            rather a summary of the investment philosophy the financial
            representative will seek to pursue on behalf of the investor.
          </Text>
        </View>
        <View style={tw('mb-5')}>
          <Text style={baseTextStyle}>
            There is no guarantee that this assessment will accurately assess
            your tolerance to risk. In addition, although the advisor may have
            directly or indirectly used the results of this assessment to
            determine a suggested asset allocation, there is no guarantee that
            the asset mix appropriately reflects your ability to withstand
            investment risk.
          </Text>
        </View>

        <View style={tw('mb-8')}>
          <Text style={secondaryTextStyleForListHeader}>
            {keyFactors.title}
          </Text>
          {keyFactors.listOfFactors.map((factor, index) => (
            <Text key={index} style={secondaryTextStyleForList}>
              • {factor}
            </Text>
          ))}
        </View>
        <View style={tw('mb-8')}>
          <Text style={secondaryTextStyleForListHeader}>
            {delibration.title}
          </Text>
          {delibration.listOfStatements.map((factor, index) => (
            <Text key={index} style={secondaryTextStyleForList}>
              • {factor}
            </Text>
          ))}
        </View>
      </View>
      <PageBottomContainer>
        {showDisclosure && (
          <Disclosure
            text={[
              `The information, data, analysis, and opinions contained in this report include the confidential and proprietary information of TIFIN Wealth; may include or be derived from account information provided by you or your financial representative which cannot be verified by TIFIN Wealth. This information may not be copied or redistributed; does not constitute investment advice and is provided solely for informational purposes only. This material does not constitute an offer to buy or sell a security. Product suitability must be independently determined for each individual investor. Except as otherwise provided by law, TIFIN Wealth shall not be responsible for any trading decisions, damages or other losses resulting from the use of this information, data, analysis or opinion. This report is supplemental sales literature. If applicable, this report should be preceded or accompanied by a prospectus, or equivalent, and disclosure statement.`,
            ]}
          />
        )}
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default IPS;
